const scripts = new Map();

type Props = {
  src: string;
  id?: string;
};

export function loadScript({src, id}: Props): Promise<void> {
  if (scripts.has(src)) {
    return scripts.get(src);
  }

  const script = document.createElement('script');
  script.async = true;
  if (id) {
    script.id = id;
  }
  const promise = new Promise<void>((resolve, reject) => {
    script.addEventListener('load', () => resolve());
    script.addEventListener('error', () =>
      reject(new Error(`Failed to load script: ${src}.`))
    );
    script.src = src;
    document.body.appendChild(script);
  });

  scripts.set(src, promise);
  return promise;
}

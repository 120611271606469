import {SalesForceBotStorage} from './types';

export const getConversationId = (botState: SalesForceBotStorage) => {
  if (!botState) return null;

  // If Unauthenticated users are allowed the conversation ID is accessible as a key in
  // the bot's local state, it is the only key with a `-` character
  const localConversationId = Object.keys(botState).find((key) =>
    key.includes('-')
  );

  if (localConversationId) return localConversationId;

  // When authenticated, conversation ID is tracked in the Salesforce backend and
  // stored in the Salesforce JWT
  if (!botState.JWT) return null;

  // JWTs use base64url, so some characters need to be replaced for atob
  // https://datatracker.ietf.org/doc/html/rfc4648#section-5
  const b64Payload = botState.JWT?.split('.')[1]
    .replace(/-/g, '+')
    .replace(/_/g, '/');
  const decodedPayload = JSON.parse(atob(b64Payload));
  return decodedPayload.clientSessionId;
};

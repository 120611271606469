/**
 * Do not edit directly, use `yarn generate:icons`
 */

import React from 'react';
import {Base} from '../../../Internal/Base/';

const pathStrings = {
  16: [
    'M13.246 1a1 1 0 01.961.725l1.065 3.726A2 2 0 0113.349 8h-9.35L4 13h2v-3h2v3h4v-3h2v3a2 2 0 01-2 2H4a2 2 0 01-2-2V7.891a2 2 0 01-1.272-2.44l1.065-3.726A1 1 0 012.754 1h10.492zm-.755 2H3.508l-.857 3H13.35l-.858-3z',
  ],

  24: [
    'M17.063 11.565c-.502.282-1.083.435-1.688.435s-1.186-.153-1.688-.435c-.501.282-1.082.435-1.687.435s-1.186-.153-1.688-.435c-.501.282-1.082.435-1.687.435s-1.186-.153-1.688-.435a3.389 3.389 0 01-.936.354L6 19.997h2v-5c0-.55.451-.997.99-.997h4.02c.546 0 .99.453.99.997v5.001l4.006.002L18 13.999 20 14v5.997A2 2 0 0118.006 22H5.994A1.996 1.996 0 014 19.997V11.77C2.835 11.322 2 10.265 2 9c0-.28.042-.556.123-.82l.725-4.354C3.02 2.796 3.956 2 5.001 2h13.998c1.04 0 1.982.803 2.152 1.826l.726 4.354c.081.264.123.54.123.82 0 1.685-1.48 3-3.25 3a3.445 3.445 0 01-1.688-.435zM12 16h-2v3.998h2V16zm7-12H5c-.064 0-.168.088-.179.155l-.737 4.426-.04.16A.73.73 0 004 9c0 .524.535 1 1.25 1 .39 0 .743-.145.978-.382l.71-.714.71.714c.234.237.587.382.977.382s.743-.145.978-.382l.71-.714.71.714c.235.237.587.382.977.382s.742-.145.978-.382l.71-.714.71.714c.235.237.587.382.977.382s.742-.145.978-.382l.71-.714.71.714c.235.237.587.382.977.382.715 0 1.25-.476 1.25-1a.82.82 0 00-.043-.26l-.04-.159-.738-4.426c-.01-.062-.12-.155-.18-.155z',
  ],
};

type Props = {size: keyof typeof pathStrings} & Omit<
  React.SVGAttributes<HTMLOrSVGElement>,
  'width' | 'height' | 'viewBox'
>;

export const IconVenue = ({
  size,
  className,
  ...rest
}: Props): React.ReactElement<React.ReactSVGElement> => {
  const paths = pathStrings[size].map((d: string, i: number) => (
    <path key={String(i)} d={d} fillRule="evenodd" />
  ));

  return (
    <Base
      as="svg"
      height={size}
      width={size}
      viewBox={`0 0 ${size} ${size}`}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      // Because viewPort(height + width) and viewBox might not have the same ratio
      // `preserveAspectRatio` indicates wether we should force a uniform scaling.
      // Therefore, `xMidYMid` centers the viewBox region within the viewPort region,
      // and `meet` scales the graphic until it meets the height and width of our viewPort.
      // More details here, https://www.digitalocean.com/community/tutorials/svg-preserve-aspect-ratio
      preserveAspectRatio="xMidYMid meet"
      fill="currentColor"
      {...rest}
    >
      {paths}
    </Base>
  );
};

IconVenue.displayName = 'IconVenue';

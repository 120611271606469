/**
 * Do not edit directly, use `yarn generate:icons`
 */

import React from 'react';
import {Base} from '../../../Internal/Base/';

const pathStrings = {
  16: [
    'M11.991 2A3.002 3.002 0 0115 5v3.765c0 1.659-1.336 3-2.998 3H6.997c-.317 0-.887.204-1.129.404l-.732.603-1.272-1.544.733-.603c.6-.494 1.62-.86 2.4-.86h5.005a.994.994 0 00.998-1V5c0-.554-.449-1-1.009-1H4.01C3.45 4 3 4.447 3 4.992V14H1V4.992A3.001 3.001 0 014.009 2h7.982zM11 6v2H5V6h6z',
  ],

  24: [
    'M19.005 3A3.004 3.004 0 0122 6.009v7.982A3.01 3.01 0 0118.991 17h-8.989c-.286 0-.802.215-1.006.418l-2.289 2.29-1.414-1.415 2.289-2.29C8.16 15.427 9.185 15 10.002 15h8.99A1.01 1.01 0 0020 13.992V6.009C20 5.452 19.552 5 19.005 5H4.995A.997.997 0 004 6v15H2V6c0-1.656 1.34-3 2.995-3h14.01zM14 11v2H6v-2h8zm4-4v2H6V7h12z',
  ],
};

type Props = {size: keyof typeof pathStrings} & Omit<
  React.SVGAttributes<HTMLOrSVGElement>,
  'width' | 'height' | 'viewBox'
>;

export const IconChat = ({
  size,
  className,
  ...rest
}: Props): React.ReactElement<React.ReactSVGElement> => {
  const paths = pathStrings[size].map((d: string, i: number) => (
    <path key={String(i)} d={d} fillRule="evenodd" />
  ));

  return (
    <Base
      as="svg"
      height={size}
      width={size}
      viewBox={`0 0 ${size} ${size}`}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      // Because viewPort(height + width) and viewBox might not have the same ratio
      // `preserveAspectRatio` indicates wether we should force a uniform scaling.
      // Therefore, `xMidYMid` centers the viewBox region within the viewPort region,
      // and `meet` scales the graphic until it meets the height and width of our viewPort.
      // More details here, https://www.digitalocean.com/community/tutorials/svg-preserve-aspect-ratio
      preserveAspectRatio="xMidYMid meet"
      fill="currentColor"
      {...rest}
    >
      {paths}
    </Base>
  );
};

IconChat.displayName = 'IconChat';

import {useSalesforceBot} from '../../../hooks';
import {InitBotProps} from '../../../hooks/useSalesforceBot/init';
import {
  PreChatFields,
  SalesForceTokens,
} from '../../../hooks/useSalesforceBot/types';
import {IconChat, IconHelp, LinkButton, Text, useViewport} from '../../Atoms';
import {Inline, Stack} from '../../Layout';
import {Modal, useModal} from '../Modal';
import {useNotify} from '../Toast';
import {generateSupportUrl} from '../../../utils';
import styles from './SupportFooter.module.css';
import {useEffect, useRef} from 'react';
import {MediaObject} from '../MediaObject';

type SupportStrings = {
  genericError: string; // Something went wrong, please try again
  helpCentre: string; // Help Centre
  chatSupport: string; // Chat support
  modal?: {
    title: string; // Have a question you need help with?
    body: string; // Log in to get help with your bookings, account, and more.
    cta: string; // Login or sign-up
    close: string; // Close
  };
};

type Props = {
  botProps: InitBotProps;
  defaultOpen?: boolean;
  layout?: 'stack' | 'inline';
  loginUrl?: string;
  onChatOpen?: (conversationId: string | null) => void;
  onChatClose?: (conversationId: string | null) => void;
  getTokens?: () => Promise<SalesForceTokens | null>;
  onHandleBotError?: (error: Error) => void;
  preChat?: PreChatFields;
  strings: SupportStrings;
  userAuthenticated?: boolean;
};

export const SupportFooter = ({
  botProps,
  defaultOpen,
  layout = 'inline',
  loginUrl = '/protected-action/?route=/account?chat',
  onChatOpen,
  onChatClose,
  getTokens,
  onHandleBotError,
  preChat,
  strings,
  userAuthenticated,
}: Props) => {
  // Records the first time the chat is opened to avoid repeat triggers
  // from defaultOpen
  const chatOpened = useRef(false);

  const handleChatOpen = (conversationId: string | null) => {
    // If the chat has been opened automatically by the Salesforce
    // script itself, we need to record this so we don't open
    // another new chat on close when defaultOpen is set
    chatOpened.current = true;
    onChatOpen && onChatOpen(conversationId);
  };

  const {loadBot, launchChat, isReady, isLoading} = useSalesforceBot({
    onChatOpen: handleChatOpen,
    onChatClose,
    getTokens,
    initialPrechat: preChat,
    ...botProps,
  });
  const {notifyError} = useNotify();
  const {modalProps, openModal} = useModal({
    width: 650,
  });
  const isMobile = useViewport({device: 'mobile'});
  const supportUrl = generateSupportUrl(botProps.locale);

  useEffect(() => {
    if (userAuthenticated) {
      loadBot().catch((error) => {
        onHandleBotError?.(error);
      });
    }
  }, [userAuthenticated, loadBot, onHandleBotError]);

  useEffect(() => {
    if (defaultOpen && isReady && !chatOpened.current) {
      launchChat(preChat)
        .then(() => (chatOpened.current = true))
        .catch((error) => {
          onHandleBotError?.(error);
        });
    }
  }, [defaultOpen, isReady, launchChat, onHandleBotError, preChat]);

  const handleChatClick = () => {
    if (!userAuthenticated) {
      openModal();
      return;
    }
    if (isLoading || !isReady) {
      notifyError(strings.genericError);
      return;
    }
    try {
      launchChat(preChat);
    } catch {
      notifyError(strings.genericError);
    }
  };

  const footerItems = (
    <>
      <a href={supportUrl} className={styles.footerItem}>
        <MediaObject space={layout === 'stack' ? 'md' : 'xs'} align="center">
          <IconHelp size={24} />
          <Text type={isMobile ? 'caption' : 'bodyHeavy'}>
            {strings.helpCentre}
          </Text>
        </MediaObject>
      </a>
      <button
        className={`${styles.footerItem} ${styles.button}`}
        onClick={handleChatClick}
        disabled={userAuthenticated && (isLoading || !isReady)}
      >
        <MediaObject space={layout === 'stack' ? 'md' : 'xs'} align="center">
          <IconChat size={24} />
          <Text type={isMobile ? 'caption' : 'bodyHeavy'}>
            {strings.chatSupport}
          </Text>
        </MediaObject>
      </button>
    </>
  );
  return (
    <>
      {layout === 'stack' ? (
        <Stack space="lg">{footerItems}</Stack>
      ) : (
        <Inline space="xl" align="center">
          {footerItems}
        </Inline>
      )}
      {strings.modal && (
        <Modal className={styles.modal} {...modalProps}>
          <Modal.Header closeLabel="Close" />
          <Modal.Body>
            <Stack space="xxl" align="center">
              <Stack space="md" align="center" justify="center">
                <Text type="xlHeader">{strings.modal.title}</Text>
                <Text type="body">{strings.modal.body}</Text>
              </Stack>

              <LinkButton
                className={styles.loginButton}
                buttonStyle="primary"
                size="lg"
                fullWidth
                href={loginUrl}
              >
                {strings.modal.cta}
              </LinkButton>
            </Stack>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

/**
 * Do not edit directly, use `yarn generate:icons`
 */

import React from 'react';
import {Base} from '../../../Internal/Base/';

const pathStrings = {
  16: [
    'M8 0a8 8 0 110 16A8 8 0 018 0zm0 2a6 6 0 100 12A6 6 0 008 2zm0 8a1.5 1.5 0 11-.001 3.001A1.5 1.5 0 018 10zm0-6.5c1.612 0 3 .925 3 2.5 0 1.285-.5 1.868-1.553 2.394C9 8.618 9 8.618 9 9H7c0-1.285.5-1.868 1.553-2.394C9 6.382 9 6.382 9 6c0-.259-.362-.5-1-.5S7 5.741 7 6H5c0-1.575 1.388-2.5 3-2.5z',
  ],

  24: [
    'M12 1c6.075 0 11 4.925 11 11s-4.925 11-11 11S1 18.075 1 12 5.925 1 12 1zm0 2a9 9 0 100 18 9 9 0 000-18zm0 12.5a1.5 1.5 0 110 3 1.5 1.5 0 010-3zM12 6c2.55 0 4 1.449 4 4 0 1.149-.5 1.924-1.609 2.974l-.585.538c-.087.08-.16.15-.227.214l-.094.092c-.354.354-.485.57-.485.682h-2c0-.782.374-1.399 1.07-2.096l.116-.113.366-.342.272-.249.317-.299c.625-.611.859-.996.859-1.401 0-1.446-.554-2-2-2-1.251 0-2 .842-2 2H8c0-2.214 1.589-4 4-4z',
  ],
};

type Props = {size: keyof typeof pathStrings} & Omit<
  React.SVGAttributes<HTMLOrSVGElement>,
  'width' | 'height' | 'viewBox'
>;

export const IconHelp = ({
  size,
  className,
  ...rest
}: Props): React.ReactElement<React.ReactSVGElement> => {
  const paths = pathStrings[size].map((d: string, i: number) => (
    <path key={String(i)} d={d} fillRule="evenodd" />
  ));

  return (
    <Base
      as="svg"
      height={size}
      width={size}
      viewBox={`0 0 ${size} ${size}`}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      // Because viewPort(height + width) and viewBox might not have the same ratio
      // `preserveAspectRatio` indicates wether we should force a uniform scaling.
      // Therefore, `xMidYMid` centers the viewBox region within the viewPort region,
      // and `meet` scales the graphic until it meets the height and width of our viewPort.
      // More details here, https://www.digitalocean.com/community/tutorials/svg-preserve-aspect-ratio
      preserveAspectRatio="xMidYMid meet"
      fill="currentColor"
      {...rest}
    >
      {paths}
    </Base>
  );
};

IconHelp.displayName = 'IconHelp';

import React, {
  useRef,
  useState,
  useEffect,
  useContext,
  ReactNode,
  MouseEvent,
} from 'react';
import {useKeyPress} from '../../../hooks';
import ReactDOM from 'react-dom';
import clsx from 'clsx';
import {IconClose, VisuallyHidden} from '../../Atoms';
import {Inline} from '../../Layout';
import {useDocumentBodyScrollLock} from './useDocumentBodyScrollLock';
import {useDetectModalBodyScroll} from './useDetectModalBodyScroll';
import {ModalContext} from './ModalContext';
import styles from './Modal.module.css';

export type ModalProps = {
  children: ReactNode | ReactNode[];
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  preventDismiss?: boolean;
  'data-cy'?: string;
  width?: number | string;
  height?: number | string;
  style?: {width?: number | string; height?: string | number};
  className?: string;
};

export const Modal = ({
  isOpen,
  title,
  children,
  preventDismiss,
  onClose,
  'data-cy': dataCy,
  style,
  className,
}: ModalProps) => {
  // Store this in context so the read/write can happen in different components
  const [isBodyScrolled, setIsBodyScrolled] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);
  const isEscapeKeyPressed = useKeyPress({
    key: 'Escape',
  });

  useEffect(() => {
    if (isEscapeKeyPressed && isOpen && !preventDismiss) {
      onClose();
    }
  }, [isEscapeKeyPressed, isOpen, preventDismiss]);

  useKeyPress({
    key: 'Tab',
    onPress: (evt, type) => {
      if (type === 'KEYDOWN') {
        onKeyboardNavigation(evt);
      }
    },
  });

  const getFocusableElements = () =>
    modalRef.current?.querySelectorAll<HTMLElement>(
      'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
    );

  const onKeyboardNavigation = (evt: KeyboardEvent) => {
    if (!modalRef.current) {
      return;
    }

    const focusableElements = getFocusableElements();
    if (!focusableElements || focusableElements.length === 0) {
      return;
    }

    // Trap focus inside the modal
    const firstElement = focusableElements[0];
    const lastElement = focusableElements[focusableElements.length - 1];

    // If the first possible focusable element is active,
    // disables the default behavior of the shift + tab navigation.
    if (evt.shiftKey && document.activeElement === firstElement) {
      lastElement.focus();
      return evt.preventDefault();
    }

    // If the last focusable element was the last one active,
    // focus on the first one in the dialog.
    if (!evt.shiftKey && document.activeElement === lastElement) {
      firstElement.focus();
      return evt.preventDefault();
    }

    // If there's only one focusable element, do not lose focus
    if (firstElement === lastElement) {
      firstElement.focus();
      return evt.preventDefault();
    }

    // If focused element is not in the modal, set it
    // to the first focusable child
    if (!modalRef.current.contains(document.activeElement)) {
      firstElement.focus();
    }
  };

  const onClickOutside = (evt: MouseEvent) => {
    if (
      (modalRef && modalRef.current?.contains(evt.target as Node)) ||
      preventDismiss
    ) {
      return;
    }
    onClose();
  };

  if (!isOpen) {
    return null;
  }

  return ReactDOM.createPortal(
    <div
      className={clsx(styles.container, className)}
      role="dialog"
      aria-modal="true"
      aria-label={title}
      aria-labelledby="modalTitle"
      aria-describedby="modalBody"
      onClick={onClickOutside}
      data-cy={dataCy}
    >
      <div ref={modalRef} className={styles.content} style={style}>
        <ModalContext.Provider
          value={{title, onClose, isBodyScrolled, setIsBodyScrolled}}
        >
          {children}
        </ModalContext.Provider>
      </div>
    </div>,
    document.body
  );
};

type CloseButtonProps = Pick<ModalHeaderProps, 'closeLabel'> & {
  inHeader?: boolean;
};

const CloseButton = ({closeLabel, inHeader}: CloseButtonProps) => {
  const {onClose} = useContext(ModalContext);
  return (
    <button
      className={clsx(styles.closeButton, {
        [styles.inHeader]: inHeader,
      })}
      onClick={onClose}
      data-cy="ModalClose"
    >
      <VisuallyHidden>{closeLabel}</VisuallyHidden>
      <IconClose className={styles.closeIcon} size={24} />
    </button>
  );
};

export type ModalHeaderProps = {
  closeLabel: string;
  hidden?: boolean;
  children?: React.ReactElement;
};

const Header = ({closeLabel, hidden, children}: ModalHeaderProps) => {
  const {isBodyScrolled} = useContext(ModalContext);
  const content = (
    <>
      {children ? (
        React.cloneElement(children as React.ReactElement, {
          id: 'modalTitle',
        })
      ) : (
        <span></span>
      )}
      <CloseButton closeLabel={closeLabel} inHeader />
    </>
  );
  return hidden ? (
    <div className={styles.headerHidden}>
      <VisuallyHidden>{content}</VisuallyHidden>
    </div>
  ) : (
    <Inline
      justify="between"
      align="center"
      className={clsx(styles.header, {
        [styles.headerShadow]: isBodyScrolled,
      })}
    >
      {content}
    </Inline>
  );
};

const Body = ({children}: {children: ReactNode}) => {
  const modalBodyRef = useRef<HTMLDivElement>(null);
  useDocumentBodyScrollLock({ref: modalBodyRef});
  useDetectModalBodyScroll(modalBodyRef);
  return (
    <div ref={modalBodyRef} className={styles.body} id="modalBody">
      {children}
    </div>
  );
};

const Footer = ({children}: {children: ReactNode}) => {
  return <div className={styles.footer}>{children}</div>;
};

Header.displayName = 'Modal.Header';
Body.displayName = 'Modal.Body';
Footer.displayName = 'Modal.Footer';
Modal.displayName = 'Modal';
CloseButton.displayName = 'CloseButton';
Modal.Header = Header;
Modal.Body = Body;
Modal.Footer = Footer;
Modal.CloseButton = CloseButton;

/**
 * Salesforce chat bot integration
 * https://developer.salesforce.com/docs/service/messaging-web/guide/api-overview.html
 */

import {useState, useReducer, useCallback, useEffect} from 'react';
import {initBot} from './init';
import type {InitBotProps} from './init';
import type {PreChatFields, SalesForceBotApi, SalesForceTokens} from './types';

import {storage} from '../../utils';
import {getSalesforceLanguage} from './getSalesforceLanguage';
import {getConversationId} from './getConversationId';

const setPrechat = (
  botInstance: SalesForceBotApi,
  prechat?: PreChatFields,
  profileAccessToken?: string
) => {
  if (prechat?.visible) {
    botInstance.prechatAPI.setVisiblePrechatFields(prechat.visible);
  }
  const hidden = {
    ...prechat?.hidden,
    profileAccessToken: profileAccessToken ?? '',
    externalLanguage: getSalesforceLanguage(
      prechat?.hidden?.externalLanguage || 'en_GB'
    ),
  };
  if (hidden) {
    botInstance.prechatAPI.setHiddenPrechatFields(hidden);
  }
};

type Props = {
  onChatOpen?: (conversationId: string | null) => void;
  onChatClose?: (conversationId: string | null) => void;
  getTokens?: () => Promise<SalesForceTokens | null>;
  initialPrechat?: PreChatFields;
} & InitBotProps;

export const useSalesforceBot = ({
  onChatOpen,
  onChatClose,
  getTokens,
  initialPrechat,
  ...botProps
}: Props) => {
  const storageName = `${botProps.init.orgId}_WEB_STORAGE`;
  const conversationId = getConversationId(
    storage.local.getItem(storageName) ?? storage.session.getItem(storageName)
  );

  const chatOpenReducer = (
    isOpen: boolean,
    payload: {type: 'OPEN_CHAT' | 'CLOSE_CHAT'; conversationId: string | null}
  ) => {
    if (payload.type === 'OPEN_CHAT' && !isOpen) {
      onChatOpen && onChatOpen(payload.conversationId);
      return true;
    }
    if (payload.type === 'CLOSE_CHAT' && isOpen) {
      onChatClose && onChatClose(payload.conversationId);
      return false;
    }
    return isOpen;
  };

  const [isLoading, setIsLoading] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [isOpen, dispatchIsOpen] = useReducer(chatOpenReducer, false);
  const [botInstance, setBotInstance] = useState<SalesForceBotApi | null>(null);
  const [tokens, setTokens] = useState<SalesForceTokens | null>(null);

  const setIdentityToken = useCallback(async () => {
    const newTokens = getTokens && (await getTokens());
    if (newTokens) {
      setTokens(newTokens);
      // On first load this can trigger before botInstance is available,
      // so we access window directly

      // Chat can launch automatically for authenticated users, so set initial
      // prechat values before authenticating
      setPrechat(
        window.embeddedservice_bootstrap,
        initialPrechat,
        newTokens.profileAccessToken
      );

      window.embeddedservice_bootstrap.userVerificationAPI.setIdentityToken({
        identityTokenType: 'JWT',
        identityToken: newTokens.chatSessionToken,
      });
    } else {
      window.embeddedservice_bootstrap.userVerificationAPI.clearSession();
    }
  }, [getTokens, initialPrechat]);

  // Set up event listeners for managing identityTokens
  useEffect(() => {
    window.addEventListener('onEmbeddedMessagingReady', setIdentityToken, {
      once: true,
    });
    window.addEventListener(
      'onEmbeddedMessagingIdentityTokenExpired',
      setIdentityToken
    );

    return () => {
      window.removeEventListener(
        'onEmbeddedMessagingIdentityTokenExpired',
        setIdentityToken
      );
    };
  }, [botInstance, setIdentityToken]);

  useEffect(() => {
    // onEmbeddedMessagingReady - SDK is bootstrapped and API functions can be called.
    // This is also called when an existing chat has ended and the bot is ready for a new session.
    const handleBotReady = () => {
      dispatchIsOpen({type: 'CLOSE_CHAT', conversationId});
      setIsReady(true);
      setIsLoading(false);
    };

    // onEmbeddedMessagingInitSuccess - The chat window has initialised and finished rendering.
    // This is particularly useful because it is triggered when the bot restores an existing chat session.
    const handleBotOpen = () => {
      dispatchIsOpen({type: 'OPEN_CHAT', conversationId});
      setIsReady(false);
      setIsLoading(false);
    };

    window.addEventListener('onEmbeddedMessagingReady', handleBotReady);
    window.addEventListener('onEmbeddedMessagingInitSuccess', handleBotOpen);

    return () => {
      window.removeEventListener('onEmbeddedMessagingReady', handleBotReady);
      window.removeEventListener(
        'onEmbeddedMessagingInitSuccess',
        handleBotOpen
      );
    };
  }, [conversationId]);

  const loadBot = useCallback(async () => {
    // If the bot has already loaded, or another instance of loadBot() is in progress
    if (botInstance || isReady || isLoading) {
      throw new Error(
        isLoading
          ? 'Trying to load bot when it is still loading'
          : 'Trying to load bot when it is already loaded'
      );
    }

    setIsLoading(true);
    setIsReady(false);
    const bot = await initBot(botProps);
    setBotInstance(bot);
    setIsLoading(false);
  }, [
    botInstance,
    isReady,
    isLoading,
    botProps,
    setBotInstance,
    setIsLoading,
    setIsReady,
  ]);

  const launchChat = useCallback(
    async (prechat?: PreChatFields) => {
      if (!isReady || isLoading || !botInstance) {
        throw new Error('Trying to launch chat when bot is not ready');
      }

      setIsLoading(true);
      setIsReady(false);
      setPrechat(botInstance, prechat, tokens?.profileAccessToken);
      await botInstance.utilAPI.launchChat();
      setIsLoading(false);
    },
    [isReady, isLoading, botInstance, tokens?.profileAccessToken]
  );

  return {
    loadBot,
    botInstance,
    launchChat,
    conversationId,
    isOpen,
    isLoading,
    isReady,
  };
};

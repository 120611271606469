import {Dispatch, SetStateAction, createContext} from 'react';

type ModalContextProps = {
  title?: string;
  onClose?: () => void;
  isBodyScrolled: boolean;
  setIsBodyScrolled: Dispatch<SetStateAction<boolean>>;
};

export const ModalContext = createContext<ModalContextProps>({
  isBodyScrolled: false,
  setIsBodyScrolled: () => {},
});

import {SALESFORCE_LOCALES} from './constants';

const isValidISOLocale = (locale: string) =>
  /^([a-z]{2})(?:[-_]?([A-Z]{2}))?$/.test(locale);

const getBaseLanguage = (locale: string) =>
  locale.substring(0, 2).toLowerCase();

type ValidSalesforceLocale =
  typeof SALESFORCE_LOCALES[keyof typeof SALESFORCE_LOCALES];

const findValidLocale = (locale: string): ValidSalesforceLocale | undefined =>
  Object.keys(SALESFORCE_LOCALES).includes(locale)
    ? SALESFORCE_LOCALES[locale as ValidSalesforceLocale]
    : undefined;

export const getSalesforceLanguage = (locale: string) => {
  if (!isValidISOLocale(locale)) {
    return SALESFORCE_LOCALES.en_GB;
  }

  const salesforceLocale =
    findValidLocale(locale) ?? findValidLocale(getBaseLanguage(locale));

  return salesforceLocale ?? SALESFORCE_LOCALES.en_GB;
};

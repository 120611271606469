import {useContext, useEffect, RefObject} from 'react';
import {ModalContext} from './ModalContext';
import {useAnimationFrame} from '../../../hooks';

export const useDetectModalBodyScroll = (ref: RefObject<HTMLDivElement>) => {
  const {setIsBodyScrolled} = useContext(ModalContext);

  // Although this will be called dozens of times as the user scrolls, React
  // will only re-render when `setIsBodyScrolled` is called with a different
  // value which occurs infrequently
  const handleScroll = useAnimationFrame(() => {
    if (ref.current && ref.current.scrollTop > 0) {
      setIsBodyScrolled(true);
    } else {
      setIsBodyScrolled(false);
    }
  }, []);

  useEffect(() => {
    ref.current?.addEventListener('scroll', handleScroll);
    return () => {
      setIsBodyScrolled(false);
      ref.current?.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);
};

import {Spacing} from '@treatwell/design-tokens';
import clsx from 'clsx';
import React from 'react';
import {Icon} from '../Icon';
import {Text} from '../Text';
import {buttonStyles} from '../Button';
import {Base} from '../../Internal';
import {Inline} from '../../Layout';
import styles from './LinkButton.module.css';

export interface LinkButtonProps extends React.ComponentPropsWithoutRef<'a'> {
  children: React.ReactNode;
  icon?: Icon;
  iconPosition?: 'left' | 'right';
  buttonStyle?: 'primary' | 'secondary';
  size?: Extract<Spacing, 'sm' | 'md' | 'lg'>;
  fullWidth?: boolean;
  disabled?: boolean;
}

export const LinkButton = React.forwardRef(
  (
    {
      children,
      disabled,
      icon,
      iconPosition = 'left',
      buttonStyle = 'primary',
      size = 'lg',
      fullWidth = false,
      className,
      ...restProps
    }: LinkButtonProps,
    ref?: React.Ref<HTMLAnchorElement>
  ) => {
    const combinedStyles = clsx(
      className,
      styles.clearLinkStyles,
      buttonStyles.button,
      buttonStyles[buttonStyle],
      buttonStyles[size],
      {
        [styles.disabled]: disabled,
        [buttonStyles.disabled]: disabled,
        [buttonStyles.fullWidth]: fullWidth,
      }
    );

    return (
      <Base
        as="a"
        className={combinedStyles}
        forwardRef={ref}
        aria-disabled={disabled}
        {...restProps}
      >
        <Inline justify="center" space="xs">
          {iconPosition === 'left' && icon}
          <Text type="bodyHeavy">{children}</Text>
          {iconPosition === 'right' && icon}
        </Inline>
      </Base>
    );
  }
);

LinkButton.displayName = 'LinkButton';

/* eslint-disable @typescript-eslint/naming-convention */
const localesMap = {
  en_GB: 'en-gb',
  en_IE: 'en-ie',
  fr_FR: 'fr-fr',
  es_ES: 'es',
  it_IT: 'it',
  de_CH: 'de-ch',
  de_AT: 'de-at',
  de_DE: 'de',
  lt_LT: 'lt',
  en_BE: 'en-be',
  fr_BE: 'fr-be',
  nl_BE: 'nl-be',
  en_NL: 'nl',
  nl_NL: 'nl',
  pt_PT: 'pt',
};
/* eslint-enable @typescript-eslint/naming-convention */

export const generateSupportUrl = (locale: string) => {
  const urlLocale =
    localesMap[locale as keyof typeof localesMap] || localesMap.en_GB;

  return `//support.treatwell.com/hc/${urlLocale}`;
};
